<template>
  <div class="loading-overlay">
    <div class="content-center" aria-label="Loading screen">
      <img v-if="sidebarLogoUrl" :src="sidebarLogoUrl" alt="Sidebar Logo" class="logo" />
      <img v-else class="mb-3" src="@/assets/images/logo.svg" width="180px" alt="RegAlytics Logo" />
      <spinner />
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
export default {
  name: "LoadingOverlay",
  components: { Spinner },
  computed: {
    // Computed property for sidebar logo URL
    sidebarLogoUrl() {
      return localStorage.getItem('sidebar_logo_url');
    },
  }
}
</script>

<style lang="scss" scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  .content-center {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>