<template>
  <div id="app">
    <!-- Theme Loading Overlay -->
    <transition name="theme-fade">
      <div v-if="isThemeLoading" class="theme-loading-overlay">
        <div class="loader-container">
          <svg class="color-wheel" viewBox="0 0 50 50">
            <circle class="loader-circle" cx="25" cy="25" r="20" fill="none" stroke-width="4"/>
            <circle class="loader-progress" cx="25" cy="25" r="20" fill="none" stroke-width="4"/>
          </svg>
          <div class="loader-text">Loading...</div>
        </div>
      </div>
    </transition>

    <!-- Custom Loading Overlay and Main Content - Visible only after theme loading is done -->
    <div v-if="!isThemeLoading">
      <div class="custom-loading-overlay">
        <transition name="fade">
          <loading-overlay v-if="getIsLoading" />
        </transition>
      </div>

      <!-- Main Content -->
      <router-view />
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import LoadingOverlay from "@/layouts/components/LoadingOverlay";
import { mapGetters } from "vuex";

export default {
  components: { LoadingOverlay },

  computed: {
    ...mapGetters("application", ["getIsLoading"]),
  },

  data() {
    return {
      defaultColor: '#4a3288', // Default purple color
      isThemeLoading: true,    // Theme loading state
    }
  },

  methods: {
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },

    forceUpdateColor(newColor) {
      try {
        const rgb = this.hexToRgb(newColor);
        const rgbString = rgb ? `${rgb.r}, ${rgb.g}, ${rgb.b}` : '74, 50, 136';

        // Update all color variables
        document.documentElement.style.setProperty('--app-primary', newColor);
        document.documentElement.style.setProperty('--bs-green-rgb', rgbString);
        document.documentElement.style.setProperty('--primary-base', newColor);
        document.documentElement.style.setProperty('--primary-color', newColor);

        // Log updates for debugging
        console.log('Color updates:', {
          hex: newColor,
          rgb: rgbString,
          variables: {
            appPrimary: getComputedStyle(document.documentElement).getPropertyValue('--app-primary'),
            bsGreenRgb: getComputedStyle(document.documentElement).getPropertyValue('--bs-green-rgb'),
            primaryBase: getComputedStyle(document.documentElement).getPropertyValue('--primary-base')
          }
        });
      } catch (error) {
        console.error('Error updating colors:', error);
        // If there's an error, fall back to default color
        if (newColor !== this.defaultColor) {
          this.forceUpdateColor(this.defaultColor);
        }
      }
    },

    async fetchThemeFromAPI(hostname) {
      try {
        const response = await axios.get('/theme/settings/', {
          params: { domain: hostname }
        });
        
        console.log('API Response:', response.data);
        if (response.data && response.data.sidebar_logo_url) {
          localStorage.setItem('sidebar_logo_url',response.data.sidebar_logo_url); 
        }
        if (response.data && response.data.sidebar_name) {
          localStorage.setItem('sidebar_name',response.data.sidebar_name); 
        }
        if (response.data && response.data.primary_color) {
          localStorage.setItem('primary_color',response.data.primary_color); 
          return response.data.primary_color;
        } 


        
        return this.defaultColor;
      } catch (error) {
        console.error('Error fetching theme from API:', error);
        return this.defaultColor;
      }
    },

    async updateTheme() {
      this.isThemeLoading = true;

      try {
        const hostname = window.location.hostname;
        console.log('Current hostname:', hostname);

        // Set default color immediately so initial content is styled
        this.forceUpdateColor(this.defaultColor);

        // Add a minimum delay for smooth animation
        const delay = new Promise(resolve => setTimeout(resolve, 800));

        if (['localhost', '127.0.0.1'].includes(hostname) || 
            /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)) {
          await delay; // Ensure minimum animation time
          console.log('Development environment detected, using default color');
        } else {
          // Run API call and delay in parallel
          const [color] = await Promise.all([
            this.fetchThemeFromAPI(hostname),
            delay
          ]);
          console.log('Setting color from API:', color);
          this.forceUpdateColor(color);
        }
      } catch (error) {
        console.error('Error in updateTheme:', error);
        this.forceUpdateColor(this.defaultColor);
      } finally {
        this.isThemeLoading = false;
      }
    }
  },

  async created() {
    await this.updateTheme();
  },

  mounted() {
    // Update on route changes if needed
    this.$router.beforeEach(async (to, from, next) => {
      await this.updateTheme();
      next();
    });
  }
};
</script>

<style lang="scss">
// Regular loading overlay styles
.custom-loading-overlay {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    transition: opacity 0.5s ease;
    opacity: 0;
  }
}

// Theme loading overlay styles
.theme-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-container {
  text-align: center;
}

.color-wheel {
  width: 80px;
  height: 80px;
  margin-bottom: 16px;
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

.loader-circle {
  stroke: #e0e0e0;
  stroke-linecap: round;
}

.loader-progress {
  stroke: var(--app-primary, #4a3288);
  stroke-linecap: round;
  stroke-dasharray: 126;
  stroke-dashoffset: 126;
  transform-origin: center;
  animation: dash 1.5s ease-in-out infinite;
}

.loader-text {
  font-size: 16px;
  color: #666;
  margin-top: 12px;
  font-weight: 500;
}

// Theme fade transition
.theme-fade-enter-active,
.theme-fade-leave-active {
  transition: opacity 0.3s ease;
}

.theme-fade-enter-from,
.theme-fade-leave-to {
  opacity: 0;
}

// Animations
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 126;
  }
  50% {
    stroke-dashoffset: 63;
  }
  100% {
    stroke-dashoffset: 126;
  }
}
</style>
