import axios from "@/axios";
import store from "@/store";


export const articleService = {
    getArticles,
    getArticlesByFilters,
    getAllStatesAgenciesAndAlertTypes,
    getTooltips,
    updateCountForCollection
}


// Get's "all articles" from the API -> Will be limited
// Can limit based on a users limits for their account
function getArticles(currentToken, currentPage) {
    return axios.get(`/articles/all?page=${currentPage}`, { withCredentials: true, cancelToken: currentToken.token })
        .then(articles => {
            return articles;
        });
}

function getTooltips() {
    return axios.get(`/articles/fetch-tooltips`, { withCredentials: true })
        .then(articles => {
            return articles;
        });
}


// getArticlesByFilters will get all articles by the search
// parameters provided from the state
function getArticlesByFilters(currentToken, currentPage, searchFilters, searchValue, selectedItems, filteredBy) {
    let url = `/articles/filter?page=${currentPage}`;
    let collectionItem = store.getters["collections/returnCollectionItem"];
    let current_url = new URL(window.location.href);
    var last_viewed = current_url.searchParams.get("last_viewed");

    if (collectionItem) {

        url = `/articles/filter?page=${currentPage}&last_viewed=${collectionItem.modified}`;
    } else if (last_viewed) {

        url = `/articles/filter?page=${currentPage}&last_viewed=${last_viewed}`;
    }

    return axios.post(url, {
        searchFilters: searchFilters,
        searchValue: searchValue,
        filters: selectedItems,
        sortBy: filteredBy
    }, {
        withCredentials: true,
        cancelToken: currentToken.token
    })
}

// Update Articles Count for user collection
// parameters provided from the state
// Updates the last viewed date for a users collection
function updateCountForCollection(collection_id, count, currentToken) {
    return axios.post('/collections/update', {
        id: collection_id,
        count: count
    }, {
        withCredentials: true,
        cancelToken: currentToken.token
    })
}


// Gets all States, Agencies and Alert Types
export function getAllStatesAgenciesAndAlertTypes() {
    return axios.get('/articles/all-search-options', {
        withCredentials: true
    });
}

export function logViewedArticle(articleId) {
    return axios.post('/telemetry/viewed', {
        article_id: articleId
    }, { withCredentials: true });
}

export function fetch_related_articles(articleId) {
    return axios.get('/collections/related/'+articleId, {}, { withCredentials: true });
}

export function logSearchHistory() {
    const searchValue = store.getters["search/getSearchValue"];
    const searchFilters = store.getters["search/getAllFilters"];
    const totalResults = store.getters["articles/getTotalResults"];

    return axios.post('/telemetry/searched', {
        search_value: searchValue,
        search_options: searchFilters,
        total_results: totalResults
    }, { withCredentials: true });
}

export function generate_base_article_url() {
    const host = window.location.origin; // Gets the base URL including protocol (e.g., http://chat.open.com)
    return `${host}/articles/dashboard`; 
}

// KEEP - Used for resetting the Vuex state in parts of the application
export const defaultSearchOptions = {
    status: {
        new: false,
        updated: false
    },

    classification: {
        sro: false,
        federal: false,
        state: false,
        territory: false,
        county: false,
        international: false,
        multinational: false

    },

    filingType: {
        single: false,
        joint: false
    },

    inFederalRegister: {
        yes: false,
        not_yet: false,
        not_expected: false
    },

    regAlyticsAlertId: '',
    federalRegisterNumber: '',
    sec_release_number: '',
    docket_file_number: '',
    order_notice: '',

    // Will use an array for Region, Agency & Alert Type
    // as a user can search with multiple different values
    state: [],
    country: [],
    agency: [],
    alertType: [],
    sector_type: [],
    tags: [],
    subtype_classification: [],
    higher_order_alert_classification: [],
    includes: [],
    orIncludes: [],
    excludes: [],

    // Date searching for our filters
    // All have a starting-to date
    latestUpdate: {
        start: null,
        end: null
    },

    originallyPublished: {
        start: null,
        end: null
    },

    proposedCommentsDue: {
        start: null,
        end: null
    },

    ruleEffective: {
        start: null,
        end: null
    },

    publishedInFederalReserve: {
        start: null,
        end: null
    }
}

