import axios from "@/axios.ts";
import changeLicense from "@/helpers/requests/accounts/changeLicense";
import createUser from "@/helpers/requests/accounts/createUser";
import loginUser from "@/helpers/requests/accounts/loginUser";
import logoutUser from "@/helpers/requests/accounts/logoutUser";
import resetPassword from "@/helpers/requests/accounts/resetPassword";
import sendPasswordResetEmail from "@/helpers/requests/accounts/sendPasswordResetEmail";
import sendSupportMessage from "@/helpers/requests/accounts/sendSupportMessage";
import sendVerificationEmail from "@/helpers/requests/accounts/sendVerificationEmail";
import verifyEmail from "@/helpers/requests/accounts/verifyEmail";
import { trackingHelper } from "@/main";
import router from "@/router";
import store from "@/store";
import { ResetPassword } from "@/types/account/ResetPassword";
import { SupportMessage } from "@/types/account/SupportMessage";
import { ArticlesLayout, User } from "@/types/account/User";
import { UserLogin } from "@/types/account/UserLogin";
import { VerifyEmail } from "@/types/account/VerifyEmail";
import { Commit, Dispatch } from "vuex";


export const accountsModule = {
    namespaced: true,

    state: {
        status: {
            loading: false,
        },
        user: {
            id: 0,
            email: null,
            telemetry: true,
            search_highlight: true,
            jwtToken: null,
            show_release_note: null,
            name: null
        } as User,
    },

    getters: {
        isLoading: (state: { status: { loading: boolean }; }) => {
            return state.status.loading;
        },

        getCurrentUser: (state: { user: User }) => {
            return state.user;
        }
    },

    mutations: {

        SET_LOADING_STATUS(state: { status: { loading: boolean; } }, value: boolean) {
            state.status = { loading: value };
        },

        SET_USER_LAYOUT(state: { user: User; }, layout: ArticlesLayout) {
            state.user.articles_layout = layout;
        },

        SET_SEARCH_HIGHLIGHT(state: { user: User; }, search_highlight: boolean) {
            state.user.search_highlight = search_highlight;
        },

        LOGIN_SUCCESS(state: { status: { loading: boolean; }; user: User; }, user: User) {
            state.status = {
                loading: false,
            };
            state.user = user;
        },

        LOGOUT_SUCCESS(state: { user: User; }) {
            state.user = {
                id: 0,
                email: null,
                telemetry: true,
                jwtToken: null,
                name: null
            } as User;
        },

        SET_ACCOUNT_DETAILS(state: { user: User; }, payload: User) {
            state.user = payload;
        },

        SET_USERS_TOKEN(state: { user: User; }, payload: string) {
            state.user.jwtToken = payload;
        },

    },

    actions: {
        create({ dispatch, commit }: { dispatch: Dispatch; commit: Commit }, user: CreateUser) {
            commit('SET_LOADING_STATUS', true);

            createUser(user)
                .then(r => {
                    commit('SET_LOADING_STATUS', false);
                    store.dispatch('notifications/addNotification', { message: `We sent you a confirmation email to ${user.email}. Make sure to click on the link to verify your account.`, type: 'green' });

                    const currentUserSignup = {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        phone: user.phone,
                        role: user.role,
                        department: user.department,
                        func: user.func,
                        accessCode: user.accessCode,
                        trial: false
                    }

                    if (!user.accessCode) currentUserSignup.trial = true;
                    trackingHelper.trackEvent('accountSignup', {
                        ...currentUserSignup
                    });

                    return router.push({ name: 'ConfirmEmail' })
                })
                .catch(err => {
                    commit('SET_LOADING_STATUS', false);
                    if (err.response.data.non_field_errors) {
                        for (const errMsg of err.response.data.non_field_errors) {
                            store.dispatch('notifications/addNotification', { message: errMsg, type: 'danger', redirectTo: '' });
                        }
                    } else if (err.response.data.detail) {
                        store.dispatch('notifications/addNotification', { message: err.response.data.detail, type: 'danger' });
                    } else if (err.response.data.password) {
                        err.response.data.password.forEach(function (msg: string) {
                            store.dispatch('notifications/addNotification', { message: msg, type: 'danger', autoHide: false })
                        });

                    } else {
                        store.dispatch('notifications/addNotification', { message: 'All fields are required for signup.', type: 'danger' });
                    }
                });
        },

        login({ dispatch, commit }: { dispatch: Dispatch; commit: Commit }, user: UserLogin) {
            commit('SET_LOADING_STATUS', true);

            loginUser(user.email, user.password)
                .then((r: { data: any; }) => {
                    trackingHelper.trackUser(r.data);

                    const userData = r.data;
                    commit('LOGIN_SUCCESS', {
                        id: userData.id,
                        email: userData.email,
                        telemetry: userData.telemetry,
                        jwtToken: userData.access,
                        name: userData.name
                    } as User);
                    axios.defaults.headers.common = { 'Authorization': `Bearer ${userData.access}` };
                    router.push({ name: 'Articles' });
                })
                .then(() => {
                    store.dispatch('notifications/clearNotification');
                    store.dispatch('tooltip/update_tooltips');
                })
                .catch((err: { response: { data: any; }; }) => {
                    commit('SET_LOADING_STATUS', false);
                    store.dispatch('notifications/addNotification', { message: err.response.data, type: 'danger' });
                    store.dispatch('application/setApplicationLoadingState', false);
                });
        },

        logout({ dispatch, commit }: { dispatch: Dispatch; commit: Commit }) {

            logoutUser()
                .then(r => {
                    store.dispatch('application/setApplicationLoadingState', true);
                    commit('LOGOUT_SUCCESS');

                    // Removes all cookies from the Browser for the current domain
                    let cookies = document.cookie.split(";");
                    for (let i = 0; i < cookies.length; i++) {
                        let cookie = cookies[i];
                        let eqPos = cookie.indexOf("=");
                        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    }

                    window.localStorage.clear();
                    trackingHelper.resetUser();
                    localStorage.removeItem('sidebar_logo_url');
                    localStorage.removeItem('sidebar_name');
                    localStorage.removeItem('primary_color');
                    store.dispatch('application/setApplicationLoadingState', false);
                    return router.push('/login');
                })
                .catch(err => {
                    // Removes all cookies from the Browser for the current domain
                    let cookies = document.cookie.split(";");
                    for (let i = 0; i < cookies.length; i++) {
                        let cookie = cookies[i];
                        let eqPos = cookie.indexOf("=");
                        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    }

                    window.localStorage.clear();
                    trackingHelper.resetUser();
                    store.dispatch('application/setApplicationLoadingState', false);
                    return router.push('/login');
                });
        },

        sendVerificationEmail({ commit }: { commit: Commit }, email: string) {
            commit('SET_LOADING_STATUS', true);

            sendVerificationEmail(email)
                .then(() => {
                    commit('SET_LOADING_STATUS', false);
                })
                .catch(() => {
                    commit('SET_LOADING_STATUS', false);
                });
        },

        sendResetPasswordEmail({ dispatch, commit }: { dispatch: Dispatch; commit: Commit }, email: string) {
            commit('SET_LOADING_STATUS', true);

            sendPasswordResetEmail(email)
                .then(() => {
                    commit('SET_LOADING_STATUS', false);
                    store.dispatch('notifications/addNotification', {
                        message: 'An email has been sent to reset your password!',
                        type: 'green'
                    });
                    store.dispatch('application/setApplicationLoadingState', false);
                    return new Promise<void>(() => {
                        setTimeout(() => {
                            return router.push('/login');
                        }, 3000);
                    })

                })
                .catch(() => {
                    commit('SET_LOADING_STATUS', false);
                    store.dispatch('notifications/addNotification', {
                        message: 'No account found, there is no RegAlytics account with the info you provided.',
                        type: 'danger',
                        autoHide: false
                    });
                    store.dispatch('application/setApplicationLoadingState', false);
                });
        },

        verifyEmail({ commit }: { commit: Commit }, tokenAndUid: VerifyEmail) {
            commit('SET_LOADING_STATUS', false);
            return verifyEmail(tokenAndUid);
        },

        resetPassword({ dispatch, commit }: { dispatch: Dispatch; commit: Commit }, userReset: ResetPassword) {
            console.log(userReset)
            store.dispatch('application/setApplicationLoadingState', true);
            commit('SET_LOADING_STATUS', true);

            resetPassword(userReset)
                .then(r => {
                    commit('SET_LOADING_STATUS', false);
                    store.dispatch('notifications/addNotification', {
                        message: 'Successfully reset your password! You can now login.',
                        type: 'green'
                    })
                    store.dispatch('application/setApplicationLoadingState', false);
                    return new Promise<void>(() => {
                        setTimeout(() => {
                            return router.push('/login');
                        }, 3000);
                    })
                })
                .catch(err => {
                    commit('SET_LOADING_STATUS', false);

                    if (err.response.status === 400) {

                        // if (err.response.data['non_field_errors'][0] === "Passwords don't match.") {
                        //     store.dispatch('notifications/addNotification', {
                        //         message: "Passwords do not match! Please try again.",
                        //         type: 'danger'
                        //     });
                        //     store.dispatch('application/setApplicationLoadingState', false);
                        // } 
                        if (err.response.data.non_field_errors) {
                            let errors = err.response.data.non_field_errors[0].split(",");
                            errors.forEach(function (msg: string) {
                                store.dispatch('notifications/addNotification', { message: msg, type: 'danger', autoHide: false })
                            });
                            store.dispatch('application/setApplicationLoadingState', false);

                        }
                        else {
                            store.dispatch('notifications/addNotification', {
                                message: 'Invalid request sent.',
                                type: 'danger'
                            });
                            store.dispatch('application/setApplicationLoadingState', false);
                            return router.push('/login');
                        }

                    }

                });
        },

        changeLicense({ dispatch, commit }: { dispatch: Dispatch; commit: Commit }, accessCode: string) {
            commit('SET_LOADING_STATUS', true);

            changeLicense(accessCode)
                .then(r => {
                    commit('SET_LOADING_STATUS', false);
                    store.dispatch('notifications/addNotification', {
                        message: `Updated your license successfully!`,
                        type: 'green'
                    });
                    return dispatch('articles/setLoadingFromExternalSource', false);
                })
                .catch(err => {
                    store.dispatch('notifications/addNotification', {
                        message: `${err.response.data.detail}`,
                        type: 'danger'
                    });
                    commit('SET_LOADING_STATUS', false);
                });
        },

        sendSupportMessage({ commit }: { commit: Commit }, supportMessage: SupportMessage) {

            sendSupportMessage(supportMessage)
                .then(r => {
                    commit('SET_LOADING_STATUS', false);
                })
                .catch(err => {
                    commit('SET_LOADING_STATUS', false);
                })
        },

        updateLoading({ commit }: { commit: Commit }) {
            commit('SET_LOADING_STATUS', false);
        },

        setAccountDetails({ commit }: { commit: Commit }, payload: User) {
            commit('SET_ACCOUNT_DETAILS', payload);
        },

        setUsersToken({ commit }: { commit: Commit }, payload: string) {
            commit('SET_USERS_TOKEN', payload);
        },

        setUserLayout({ commit }: { commit: Commit }, layout: ArticlesLayout) {
            commit('SET_USER_LAYOUT', layout);
        },

        setSearchHighlight({ commit }: { commit: Commit }, search_highlight: boolean) {
            commit('SET_SEARCH_HIGHLIGHT', search_highlight);
        }



    }
};
